import './style.sass';
import {UserState} from './ts/user-state';
import {StepConfig} from "./ts/step-config";
import * as $ from 'jquery';
import 'bootstrap';
import {UserStateService} from "./ts/user-state-service";

const userStateService = new UserStateService();

const sendValue = (clientId: string, name: string, value: string) => {
    return $.ajax({
        url: 'https://ppcrm.vimsoft.eu/assessment',
        type: 'post',
        dataType: 'json',
        data: {
            questionnaire_id: '1',
            user_id: clientId,
            property_name: name,
            property_value: value
        }
    });
}

const sendValues = (clientId: string, obj: {[name: string]: string}) => {
    let promises = [];
    for (let entry in obj) {
        promises.push(sendValue(clientId, entry, obj[entry]));
    }
    return Promise.all(promises);
}

const injectedFirstNameClass = '.injected-first-name';

const congratsArr: string[] = ['congratulations', 'needHelp'];
const congratsArrLength = congratsArr.length;
const resultsArr: string[] = ['novice', 'beginner', 'amateur', 'advanced', 'semiPro', 'pro'];

const bulletNames = {
    bullet1: 'bullet-1',
    bullet2: 'bullet-2',
    bullet3: 'bullet-3',
    bullet4: 'bullet-4'
};
const selectBullet = (id: string) => {
    $('.wizard .nav-tabs li').addClass('disabled');
    $('.wizard .nav-tabs li').removeClass('active');

    $(`#${id}`).removeClass('disabled');
    $(`#${id}`).addClass('active');
}

const steps: { [id: string]: StepConfig } = {
    'aboutYou': {
        getPreviousStep: () => {
            return 'aboutYou';
        },
        getNextStep: () => {
            return 'notAlone';
        },
        initialize: state => {
            $('#aboutYou').show();
            selectBullet(bulletNames.bullet1);
        }
    },
    'notAlone': {
        getPreviousStep: () => {
            return "aboutYou";
        },
        getNextStep: () => {
            return 'moreInfo';
        },
        initialize: (newState: UserState) => {
            const mattersArr: string[] = ['paycheck-cycle', 'debt', 'retirement', 'save'];

            for (let i = 0; i < mattersArr.length; i++) {
                if (mattersArr[i] === newState.matters) {
                    $(`.${mattersArr[i]}`).show();
                } else {
                    $(`.${mattersArr[i]}`).hide();
                }
            }

            $('#notAlone').show();
            selectBullet(bulletNames.bullet1);
        }
    },
    'moreInfo': {
        getPreviousStep: () => {
            return 'notAlone';
        },
        getNextStep: () => {
            return 'feeling';
        },
        initialize: state => {
            $('#moreInfo').show();
            selectBullet(bulletNames.bullet1);
        }
    },
    'feeling': {
        getPreviousStep: () => {
            return 'moreInfo';
        },
        getNextStep: () => {
            return 'handlingMoney';
        },
        initialize: state => {
            $('#feeling').show();
            selectBullet(bulletNames.bullet2);
        }
    },
    'handlingMoney': {
        getPreviousStep: () => {
            return 'feeling';
        },
        getNextStep: () => {
            return 'moneyDiscussion';
        },
        initialize: state => {
            $('#handlingMoney').show();
            selectBullet(bulletNames.bullet2);
        }
    },
    'moneyDiscussion': {
        getPreviousStep: () => {
            return 'handlingMoney';
        },
        getNextStep: () => {
            return 'moneyQuestions';
        },
        initialize: state => {
            $('#moneyDiscussion').show();
            selectBullet(bulletNames.bullet2);
        }
    },
    'moneyQuestions': {
        getPreviousStep: () => {
            return 'moneyDiscussion';
        },
        getNextStep: () => {
            return 'yearlyIncome';
        },
        initialize: state => {
            $('#moneyQuestions').show();
            selectBullet(bulletNames.bullet2);
        }
    },
    'yearlyIncome': {
        getPreviousStep: () => {
            return 'moneyQuestions';
        },
        getNextStep: () => {
            return 'aboutDept';
        },
        initialize: state => {
            $('#yearlyIncome').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'aboutDept': {
        getPreviousStep: () => {
            return 'yearlyIncome';
        },
        getNextStep: () => {
            return 'congratulationsOrNot';
        },
        initialize: state => {
            $('#aboutDept').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'congratulationsOrNot': {
        getPreviousStep: () => {
            return 'aboutDept';
        },
        getNextStep: () => {
            return 'emergency';
        },
        initialize: (newState: UserState) => {
            for (var i = 0; i < congratsArrLength; i++) {
                if (congratsArr[i] === newState.congrats) {
                    $(`.${congratsArr[i]}`).show();
                } else {
                    $(`.${congratsArr[i]}`).hide();
                }
            }

            $('#congratulationsOrNot').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'emergency': {
        getPreviousStep: () => {
            return 'congratulationsOrNot';
        },
        getNextStep: () => {
            return 'economies';
        },
        initialize: state => {
            $('#emergency').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'economies': {
        getPreviousStep: () => {
            return 'emergency';
        },
        getNextStep: () => {
            return 'retirementInvestment';
        },
        initialize: state => {
            $('#economies').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'retirementInvestment': {
        getPreviousStep: () => {
            return 'economies';
        },
        getNextStep: () => {
            return 'studyFees';
        },
        initialize: state => {
            $('#retirementInvestment').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'studyFees': {
        getPreviousStep: () => {
            return 'retirementInvestment';
        },
        getNextStep: () => {
            return 'houseStatus';
        },
        initialize: state => {
            $('#studyFees').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'houseStatus': {
        getPreviousStep: () => {
            return 'studyFees';
        },
        getNextStep: () => {
            return 'houseInvestments';
        },
        initialize: state => {
            $('#houseStatus').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'houseInvestments': {
        getPreviousStep: () => {
            return 'houseStatus';
        },
        getNextStep: (state) => {
            if (state.houseInvest !== 'no') {
                return 'doYouHaveMoney';
            }
            return 'thankYou';
        },
        initialize: state => {
            $('#houseInvestments').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'doYouHaveMoney': {
        getPreviousStep: () => {
            return 'houseInvestments';
        },
        getNextStep: () => {
            return 'thankYou';
        },
        initialize: (newState: UserState) => {
            $('#doYouHaveMoney').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'thankYou': {
        getPreviousStep: () => {
            return 'houseInvestments';
        },
        getNextStep: () => {
            return 'result';
        },
        initialize: (newState: UserState) => {
            $('#thankYou').show();
            selectBullet(bulletNames.bullet3);
        }
    },
    'result': {
        getPreviousStep: () => {
            return 'result';
        },
        getNextStep: () => {
            return 'result';
        },
        initialize: (newState: UserState) => {
            const result = calculatingResult(newState);
            for (let i = 0; i < resultsArr.length; i++) {
                if (resultsArr[i] === result) {
                    $(`.${resultsArr[i]}`).show();
                }
            }

            $('#result').show();
            selectBullet(bulletNames.bullet4);

            userStateService.remove();
        }
    }
}

const hideAllSections = () => {
    for(const sectionName in steps) {
        $(`#${sectionName}`).hide();
    }
}

const configSection = (sectionName: string, state: UserState) => {
    const sectionConfig = steps[sectionName];
    if (sectionConfig) {
        hideAllSections();
        sectionConfig.initialize(state);
        window.location.hash = `#${sectionName}`;
    }
}
const changeSection = (currentSection: string, next: boolean, state: UserState) => {
    const currentSectionConfig = steps[currentSection];
    if (currentSectionConfig) {
        let sectionName: string;
        if (next) {
            sectionName = currentSectionConfig.getNextStep(state);
        } else {
            sectionName = currentSectionConfig.getPreviousStep();
        }
        configSection(sectionName, state);
    }
}
function calculatingResult(newState: UserState): string {
    if (newState.congrats === "needHelp" && newState.starter_emergency_fund === "false") {
        return "novice";
    }
    if (newState.congrats === "needHelp"
      && newState.starter_emergency_fund === "true") {
        return "beginner";
    }
    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && (newState.current_economies === "less"
        || newState.current_economies === "between")) {
        return "amateur";
    }
    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && newState.current_economies === "more"
      && (newState.investing === "zero"
        || newState.investing === "less-than-15-percent"))
        return 'advanced';
    if (newState.congrats === "congratulations"
        && newState.starter_emergency_fund === "true"
        && newState.current_economies === "more"
        && (newState.investing === "15-percent"
            || newState.investing === "more-than-15-percent")
        && (newState.house === "rent"
          || newState.house === "no-rent")
        && newState.houseInvest === "yes"
        && newState.money_for_house === "true")
        return 'advanced';
    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && newState.current_economies === "more"
      && (newState.investing === "15-percent"
        || newState.investing === "more-than-15-percent")
      && newState.house === "mortgage")
        return "semiPro";
    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && newState.current_economies === "more"
      && (newState.investing === "15-percent"
      || newState.investing === "more-than-15-percent")
      && (newState.house === "rent"
        || newState.house === "no-rent")
      && newState.houseInvest === "yes"
      && newState.money_for_house === "true")
        return "semiPro";

    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && newState.current_economies === "more"
      && (newState.investing === "15-percent"
        || newState.investing === "more-than-15-percent")
      && newState.house === "paid")
      return "pro";
    if (newState.congrats === "congratulations"
      && newState.starter_emergency_fund === "true"
      && newState.current_economies === "more"
      && (newState.investing === "15-percent"
        || newState.investing === "more-than-15-percent")
      && (newState.house === "rent"
        || newState.house === "no-rent")
      && newState.houseInvest === "no")
        return "pro";

    return 'novice';
}

const defaultStartingStep = "aboutYou";

$(document).ready(function () {
    (<any>$('.nav-tabs > li a[title]')).tooltip();

    $("form").submit((e) => {
        e.preventDefault();
        e.stopPropagation();

        const formElements: { name: string, value: string }[] = $(e.target).serializeArray();
        const newStateProps = formElements.reduce((agg, curr) => {
            agg[curr.name] = curr.value;
            return agg;
        }, <{ [id: string]: any }>{});

        const oldState = userStateService.get();
        const newState: UserState = { ...oldState, ...newStateProps };
        userStateService.set(newState);

        $(injectedFirstNameClass).html(newState.firstName || '');

        changeSection(e.target.id, true, newState);

        // sendValues(newState.clientId, newStateProps);

        return false;
    });

    $(".prev-step").click(function (e: any) {
        const state = userStateService.get();
        const form = $(e.target).parents('form').first();
        if (form) {
            changeSection(form.attr('id') || '', false, state);
        }
    });

    const state = userStateService.get();
    if (window.location.hash){
        const supposedSection = window.location.hash.replace('#', '');
        if (Object.keys(steps).indexOf(supposedSection) !== -1) {
            if (state) {
                configSection(supposedSection, state);
                return;
            }
        }
    }
    configSection(defaultStartingStep, state);
});
