import {uuid} from "uuidv4";
import {UserState} from "./user-state";

export class UserStateService {
	public get(): UserState {
		const raw = localStorage.getItem('state') || '';
		try {
			const userState = JSON.parse(raw);
			return userState;
		}
		catch (e) {
			const newUserState = {
				clientId: uuid(),
				firstName: undefined,
				matters: undefined,
				congrats: undefined,
				current_economies: undefined,
				starter_emergency_fund: undefined,
				money_emotion: undefined,
				handle_money: undefined,
				investing: undefined,
				house: undefined,
				houseInvest: undefined,
				moneyForHouse: undefined,
				result: undefined
			};

			this.set(newUserState);

			return newUserState;
		}
	};

	public set(userState: UserState) {
		const raw = JSON.stringify(userState);
		localStorage.setItem('state', raw);
	}

	public remove() {
		localStorage.removeItem('state');
	};
}